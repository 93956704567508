<template>
	<div >
		
		<div class="ft20 cl-black cl-main ftw500">海报库</div>
		
		<div class="mt20">
			<div class="bg-w pd30" style="min-height: 600px;">
				<div class="flex alcenter">
					<a-button type="primary" icon="plus" @click="addPosterAct()">添加海报</a-button>
				</div>
				<div class="mt30">
					<div class="wxb-table-gray">
						<a-table rowKey="poster_template_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="loading">
							
							
							<div class="flex alcenter center poster-back-img" slot="back_img" slot-scope="back_img,record">
								<img v-if="back_img != null" :src="back_img"/>
								<div v-else>暂未上传</div>
							</div>
							
							
							<template slot="action" slot-scope="record">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											  <a-menu-item>
													<a class="menu-act" href="javascript:;" @click="editPosterAct(record)">
														<i class="iconfont ft14 iconedit"></i>
														<span class="ml10">编辑</span>
													</a>
											  </a-menu-item>
											  <a-menu-item>
													<a class="menu-act" href="javascript:;" @click="delPosterAct(record)">
														<i class="iconfont ft14 icondelete"></i>
														<span class="ml10">删除</span>
													</a>
											  </a-menu-item>
										</a-menu>
									  </a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			
			</div>
		</div>
	</div>
				
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					mobile:'',
					status:0,
				},
				to_do_num:0,
				columns: [
					{title: 'id',dataIndex: 'poster_template_id',align: 'center',ellipsis: true},
					{title: '背景图片',dataIndex: 'back_img',align: 'center',scopedSlots: {customRender: 'back_img'}},
					{title: '文案',dataIndex: 'copywriting',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas: [],
			}
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.papi('platform/terminal/getShopPosterList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			addPosterAct(){
				this.$router.push('/partner/poster/edit');
			},
			
			editPosterAct(record){
				this.$router.push('/partner/poster/edit?id='+record.poster_template_id);
			},
			
			delPosterAct(record){
				this.$confirm({
					title:'确认删除这个海报吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.papi('platform/terminal/delShopPoster',{
								poster_template_id:record.poster_template_id
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
		}
	}
</script>

<style>
	.poster-back-img img{
		width: 30px;
		height: 60px;
	}
</style>
